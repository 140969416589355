import FS from 'services/FetchService';

// // Curriculum Sections
// Route::get('/curricula/{curriculum}/sections', 'CurriculumSectionController@getSections');
// Route::get('/curricula/{curriculum}/sections/{id}', 'CurriculumSectionController@getSection');
// Route::post('/curricula/{curriculum}/sections', 'CurriculumSectionController@createSection');
// Route::patch('/curricula/{curriculum}/sections/{id}', 'CurriculumSectionController@updateSection');
// Route::delete('/curricula/{curriculum}/sections/{id}', 'CurriculumSectionController@deleteSection');

// // School Curriculum Sections
// Route::get('/schools/{id}/curriculum_sections', 'SchoolController@getCurriculumSections');
// Route::post('/schools/{id}/curriculum_sections', 'SchoolController@addCurriculumSections');
// Route::delete('/schools/{id}/curriculum_sections', 'SchoolController@removeCurriculumSections');

// // Curriculum Library
// Route::get('/curricula/{id1}/sections/{id2}/library', 'CurriculumSectionController@getLibraryMedia');
// Route::post('/curricula/{id1}/sections/{id2}/library', 'CurriculumSectionController@addLibraryMedia');
// Route::delete('/curricula/{id1}/sections/{id2}/library', 'CurriculumSectionController@removeLibraryMedia');
export default class CurriculumSectionService {
  static async fetch({ CurriculumID, SchoolID = '', role = '' }) {
    let route;
    if (SchoolID) {
      if (role === 'SuperAdmin') {
        route = `/schools/${SchoolID}/curriculum_sections?limit=0`;
      } else if (role === 'SchoolAdmin') {
        route = '/current_user/school/curriculum_sections?limit=0';
      }
    } else {
      route = `/curricula/${CurriculumID}/sections?limit=0`;
    }
    return FS.get(route);
  }

  static async pageMedia(params, { CurriculumID:cid, CurriculumSectionID:csid }) {
    return FS.get(`/curricula/${cid}/sections/${csid}/library?${params}`);
  }
  static async pageTeacherMedia(params, { CurriculumID:cid, CurriculumSectionID:csid }) {
    return FS.get(`/curricula/${cid}/sections/${csid}/teacher_library?${params}`);
  }

  static async orderMedia(Media, SortPriority, { CurriculumID:cid, CurriculumSectionID:csid }) {
    return FS.patch(`/curricula/${cid}/sections/${csid}/library/sort_priority`, JSON.stringify({ Media, SortPriority }), {
      header: h => h.append('Content-Type', 'application/json')
    });
  }

  static async orderTeacherMedia(Media, SortPriority, { CurriculumID:cid, CurriculumSectionID:csid }) {
    return FS.patch(`/curricula/${cid}/sections/${csid}/teacher_library/sort_priority`, JSON.stringify({ Media, SortPriority }), {
      header: h => h.append('Content-Type', 'application/json')
    });
  }

  static modifyMedia = (method, key) => async (Media, { CurriculumID, CurriculumSectionID }) => {
    return FS[method](`/curricula/${CurriculumID}/sections/${CurriculumSectionID}/${key}`, JSON.stringify(Media), {
      header: h => h.append('Content-Type', 'application/json')
    });
  }
  static addMedia = CurriculumSectionService.modifyMedia('post', 'library');
  static removeMedia = CurriculumSectionService.modifyMedia('delete', 'library');
  static addTeacherMedia = CurriculumSectionService.modifyMedia('post', 'teacher_library');
  static removeTeacherMedia = CurriculumSectionService.modifyMedia('delete', 'teacher_library');

  static async save(section, { CurriculumID }) {
    let isNew = !section.CurriculumSectionID
    let method = isNew ? 'post' : 'patch';
    let route = `/curricula/${CurriculumID}/sections`;
    if (!isNew) {
      route += `/${section.CurriculumSectionID}`;
    }
    return FS[method](route, JSON.stringify(section), {
      header: h => h.append('Content-Type', 'application/json')
    });
  }

  static async delete(section, { CurriculumID:cid }) {
    return FS.delete(`/curricula/${cid}/sections/${section.CurriculumSectionID}`);
  }

  static modifySchoolSection = method => (CurriculumSectionID, { role, SchoolID }) => {
    let pre;
    if (role === 'SchoolAdmin') {
      pre = `/current_user/school`;
    } else if (role === 'SuperAdmin') {
      pre = `/schools/${SchoolID}`;
    }
    let path = `${pre}/curriculum_sections`;
    return FS[method](path, JSON.stringify({ CurriculumSections: [CurriculumSectionID] }), {
      header: h => h.append('Content-Type', 'application/json')
    });
  }

  static addSectionToSchool = CurriculumSectionService.modifySchoolSection('post');

  static removeSectionFromSchool = CurriculumSectionService.modifySchoolSection('delete');
}