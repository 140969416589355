import { useMediaQuery } from "@mui/material";
import { sizes } from "components/media/media";
import { createContext, useEffect, useRef, useState } from "react";

type DrawerContextType = ReturnType<typeof useDrawerContext>;

export const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

export const useDrawerContext = () => {
  const shouldDrawerOpen = useMediaQuery(`(min-width:${sizes.portrait}px)`);
  const [drawerOpen, setDrawerOpen] = useState(window.innerWidth >= sizes.portrait);

  const skipFirst = useRef(true); 
  useEffect(() => {
    // useMediaQuery always initializes to false for some reason, so we skip first render
    if (skipFirst.current) {
      skipFirst.current = false;
      return;
    }
    setDrawerOpen(shouldDrawerOpen)
  }, [shouldDrawerOpen]);

  return {
    drawerOpen, 
    setDrawerOpen,
    shouldDrawerOpen,
  }
}