import FS from '../FetchService';

function whiteSpaceOnly(str) {
  return !str.replace(/\s/g, '').length;
}

export default class WorksheetService {
  static async page(params) {
    return FS.get(`/worksheets?${params}`);
  }

  static async create() {
    return FS.post("/worksheets");
  }

  static async createWithData(worksheet) {
    return FS.post("/worksheets", worksheet, { header: h => h.append('Content-Type', 'application/json')});
  }

  static async get(WorksheetID, disableNotify = false) {
    return FS.get(`/worksheets/${WorksheetID}`, disableNotify);
  }

  static async save(WorksheetID, worksheet) {
    return FS.patch(`/worksheets/${WorksheetID}`, worksheet);
  }

  static async delete(WorksheetID) {
    return FS.delete(`/worksheets/${WorksheetID}`);
  }

  static async uploadDataURI(WorksheetID, dataURI) {
    const file = await WorksheetService.convertDataURIToFile(dataURI);
    const result = await FS.post(
      `/worksheets/${WorksheetID}/images`,
      FS.jsonToForm({
        image: file,
      }),
      { response: "text" }
    );
    return result;
  }

  static async convertDataURIToFile(dataURI) {
    const request = await fetch(dataURI);
    const buffer = await request.arrayBuffer();
    return new File([buffer], "image.png", { type: "image/png" });
  }

  static validateWorksheet(worksheet) {
    const validations = [];

    if (!worksheet.Name) {
      validations.push("Worksheet is missing a title.");
    }

    let questionIndex = -1;
    worksheet.Pages.forEach((page, pageIndex) => {
      page.Questions.forEach((question) => {
        questionIndex++;
        let pre = `Page ${pageIndex + 1}: Question ${questionIndex + 1}: `;
        if (!question.Image && !question.Name) {
          validations.push(`${pre}Missing text, or an image.`);
        }
        switch (question.Type) {
          case "match-up":
            if (question.Body.length < 2) {
              validations.push(
                `${pre}Match Up requires at least 2 answer pairs.`
              );
            }
            if (
              !question.Body.every((b) => !!b.value) ||
              !question.Answer.every((a) => !!a.value)
            ) {
              validations.push(
                `${pre}There are empty answers in the match-up body.`
              );
            }
            break;
          case "short-answer":
            if (
              !question.Answer[0].value ||
              whiteSpaceOnly(question.Answer[0].value)
            ) {
              validations.push(`${pre}Short Answer is missing an answer.`);
            }
            if (
              !question.Answer.every(
                (a) => !!a.value || whiteSpaceOnly(a.value)
              )
            ) {
              validations.push(
                `${pre}There are empty valid answers in the short-answer.`
              );
            }
            break;
          case "multiple-choice":
            if (question.Body.length < 2) {
              validations.push(
                `${pre}Multiple Choice requires at least 2 possible answers.`
              );
            }
            if (!question.Answer.length || !question.Answer[0].value) {
              validations.push(
                `${pre}Multiple Choice is missing the correct answer.`
              );
            }
            break;
          case "fill-blanks":
            if (question.Body.length < 2) {
              validations.push(
                `${pre}Fill in the Blanks requires at least 2 blanks.`
              );
            }
            if (
              !question.Answer.every(
                (a) => !!a.value && !whiteSpaceOnly(a.value)
              )
            ) {
              validations.push(
                `${pre}There are empty answers in the fill-in-the-blanks body.`
              );
            }
            break;
          default:
            break;
        }
      });
    });
    if (questionIndex === -1) {
      validations.push("Worksheet must have at least one question.");
    }

    return validations;
  }
}