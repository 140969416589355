import React, { useState } from "react";
import styled from "styled-components";
import Link from "components/Link/Link";
import media from "../media/media";

import NavbarIcon from "./NavbarIcon";
import Tooltip from "components/Tooltips/Tooltip";

const SMALL_HORIZONTAL_SPACE = `${(12 / 1920) * 100}vw`;
// const MEDIUM_HORIZONTAL_SPACE = `${(16 / 1920) * 100}vw`;
const LARGE_HORIZONTAL_SPACE = `${(22 / 1920) * 100}vw`;

const ICON_LENGTH = "32px";

const Wrapper = styled.div`
  position: relative;
  height: ${({ side }) => (side ? "50px" : "100%")};
  display: flex;
  align-items: center;
  justify-content: ${({ side }) => (side ? "center" : "space-around")};
  cursor: ${({ displayOnly }) => (displayOnly ? "auto" : "pointer")};
  overflow: visible;
  transition: all 0.2s;
  background-color: ${({ expanded, active }) =>
    expanded || active ? "rgba(0,0,0,0.2)" : "transparent"};
  padding-left: ${({ side }) => (side ? "0" : 0)}; //'16px' for side previously

  &:hover {
    ${({ displayOnly }) => !displayOnly && "background-color: rgba(0,0,0,0.2);"}
  }
`;

const Text = styled.span`
  font-family: RobotoMedium;
  color: white;
  padding-left: ${({ hasIcon }) => (hasIcon ? 0 : LARGE_HORIZONTAL_SPACE)};
  padding-right: ${LARGE_HORIZONTAL_SPACE};
  margin-left: ${({ side }) => (side ? "32px" : 0)};
`;

const Image = styled.img`
  // width: 15%;
  // margin: auto;

  width: ${({ width, tutorialVideos }) =>
    width ? width : tutorialVideos ? "70%" : ICON_LENGTH};
  height: ${({ height, tutorialVideos }) =>
    height ? height : tutorialVideos ? "80%" : ICON_LENGTH};
  margin-left: ${SMALL_HORIZONTAL_SPACE};
  margin-right: ${SMALL_HORIZONTAL_SPACE};
`;

const I = styled.i`
  color: white;
  font-weight: bold;
  margin-left: ${SMALL_HORIZONTAL_SPACE};
  margin-right: ${({ large }) =>
    large ? SMALL_HORIZONTAL_SPACE : LARGE_HORIZONTAL_SPACE};
  ${({ large }) => large && "font-size: 30px"};
`;

const Expander = styled.div`
  position: absolute;
  min-width: ${({ helpItemsExpander }) =>
    helpItemsExpander ? "235" : "150"}px; //150px 235px

  top: 72px;
  ${({ dropDownSide }) => dropDownSide === "left" && "left: 0;"}
  ${({ dropDownSide }) => dropDownSide === "right" && "right: 0;"}
    z-index: 100;
  background-color: #303030;
  overflow: hidden;
  transition: all 0.2s;

  transform: translateX(${({ expanded }) => (expanded ? 0 : "5px")});
  opacity: ${({ expanded }) => (expanded ? 1 : 0)};
  pointer-events: ${({ expanded }) => (expanded ? "auto" : "none")};
  display: ${({ expanded }) => (expanded ? "block" : "none")};

  ${media.portrait`
    top: 48px;
  `}
`;

const ExpandedItem = styled.div`
  display: flex;

  height: 72px;
  width: 100%;
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;

  color: white;
  font-family: Roboto;
`;

const UCTainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
UCTainer.displayName = "UCTainer";

const UnderConstruction = styled.div`
  background-color: black;
  border: 3px dotted yellow;
  transform: rotate(17deg);
  color: yellow;
  padding: 2px;
  font-size: 10px;
  opacity: 0.8;
`;

const NavBarItem = (props) => {
  const [state, setState] = useState({
    expanded: false,
  });

  const handleClick = () => {
    setState((orig) => ({ ...orig, expanded: !state.expanded }));
  };

  const renderItem = (
    {
      title,
      icon,
      to,
      onClick,
      className,
      iconInDropdown,
      preventFlipX,
      tutorialVideos,
    },
    i
  ) => {
    let item = (
      <ExpandedItem className={className} key={i} onClick={onClick}>
        {iconInDropdown ? (
          <NavbarIcon
            title={title}
            icon={icon}
            flipX={!preventFlipX}
            tutorialVideos={tutorialVideos}
          />
        ) : (
          title
        )}
      </ExpandedItem>
    );

    if (to) {
      item = (
        <Link key={i} to={to}>
          {item}
        </Link>
      );
    }

    return item;
  };

  const {
    items,
    icon,
    title,
    grade,
    tooltip,
    active,
    side,
    to,
    uc,
    className,
    customDropIcon,
    id,
    tourExpanded,
    displayOnly,
    fontIcon,
    image,
    dropDownSide,
    iconMode,
    helpItemsExpander,
    tutorialVideos,
  } = props;
  const expanded = tourExpanded == null ? state.expanded : tourExpanded;

  const children = (
    <Tooltip
      tooltip={tooltip}
      tooltipOptions={{ float: { vertical: "bottom", horizontal: "auto" } }}
    >
      <Wrapper
        id={id}
        className={className}
        side={side}
        active={active}
        expanded={expanded}
        onClick={items ? handleClick : props.onClick}
        displayOnly={displayOnly}
        dropdown={items}
        helpItemsExpander={helpItemsExpander}
      >
        {icon && <Image src={icon} tutorialVideos={tutorialVideos} />}
        {fontIcon && <I className={fontIcon} large />}
        {title &&
          (iconMode ? null : (
            <Text side={side} hasIcon={!!icon}>
              {title}
              <br />
              {grade ? grade : null}
            </Text>
          ))}
        {image && <Image src={image} width="auto" height="52px" />}
        {items &&
          (!!customDropIcon ? (
            <Image src={customDropIcon} />
          ) : (
            <I className="fas fa-angle-down" />
          ))}
        {items && (
          <Expander
            expanded={expanded}
            dropDownSide={dropDownSide}
            helpItemsExpander={helpItemsExpander}
          >
            {items.map(renderItem)}
          </Expander>
        )}
        {uc && (
          <UCTainer>
            <UnderConstruction>Under Construction</UnderConstruction>
          </UCTainer>
        )}
      </Wrapper>
    </Tooltip>
  );

  if (to) {
    return <Link to={to}>{children}</Link>;
  } else {
    return children;
  }
};

export default NavBarItem;
