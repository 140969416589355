import { AlertColor, Snackbar, Alert, Slide, SlideProps } from "@mui/material";
import { useRef, useState, useCallback, useMemo, useEffect } from "react";

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

interface SnackbarState {
  closeDelay: number,
  message: string,
  severity: AlertColor,
}

export const useSnackbar = (topOffset = 0) => {
  const reopenTimeoutRef = useRef<NodeJS.Timeout>();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    closeDelay: 3700,
    message: '',
    severity: 'success', 
  });
  
  const onCloseSnackbar = useCallback((_?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  }, []);
  const openSnackbar = useCallback((message: string, severity: AlertColor = 'success', closeDelay = 3700) => {
    const onOpen = () => { 
      setSnackbarState({
        closeDelay,
        message,
        severity,
      })
      setSnackbarOpen(true); 
    }
    if (!snackbarOpen) return onOpen();

    clearTimeout(reopenTimeoutRef.current);
    setSnackbarOpen(false);
    reopenTimeoutRef.current = setTimeout(onOpen, 250);
  }, [snackbarOpen]);

  // Cleanup function
  useEffect(() => () => {
    setSnackbarOpen(false);
    clearTimeout(reopenTimeoutRef.current);
  }, []);
  
  const snackbarComponent = useMemo(() => (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={snackbarState.closeDelay}
      onClose={onCloseSnackbar}
      TransitionComponent={SlideTransition}
      sx={{transform: `translateY(${80 + topOffset}px)`}}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Alert onClose={onCloseSnackbar} severity={snackbarState.severity} sx={{ width: '100%' }}>
        {snackbarState.message}
      </Alert>
    </Snackbar>
  ), [onCloseSnackbar, snackbarOpen, snackbarState.closeDelay, snackbarState.message, snackbarState.severity, topOffset]);

  return { snackbarComponent, openSnackbar, reset: onCloseSnackbar };
}